<template>
  <v-container
    id="login"
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        md="6"
        class="d-none d-md-block pa-0"
      >
        <v-img
          height="100vh"
          cover
          class="d-flex align-center justify-center"
          src="@/assets/login_images/left-img.png"
        >
          <v-img
            contain
            width="50%"
            class="d-flex align-center justify-center mx-auto"
            src="@/assets/logo-big.svg"
          />
        </v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center justify-center mx-auto pa-0"
      >
        <div>
          <v-row
            justify="center"
            class="py-2"
          >
            <v-col
              cols="11"
              md="11"
              lg="10"
              xl="10"
            >
              <v-img
                contain
                width="90px"
                class=""
                src="@/assets/logo.svg"
              />
            </v-col>
            <v-col
              cols="11"
              md="11"
              lg="10"
              xl="10"
            >
              <div class="font-weight-regular text-h6">
                ¡ Bienvenido !
              </div>
              <div class="font-weight-negrita text-h5">
                Iniciar sesión
              </div>
            </v-col>
          </v-row>
          <custom-form
            validate
            :form="form"
            width-md="11"
            :loading="loading"
            width-lg="10"
            width-xl="10"
            @submit="initialize"
            @recover="init('recover')"
            @back="init('login')"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import { setCookie, removeCookie } from '@/util/cookies'
  import { get, sync, call } from 'vuex-pathify'

  export default {
    name: 'Login',
    components: { },
    data () {
      return {

        form: {},
        formSelected: 'login',
        loading: false,
      }
    },
    computed: {
      query: get('route/query'),
    },
    async mounted () {
      window.localStorage.clear()
      await removeCookie()
      this.init('login')
    },
    methods: {
      getUserInfo: call('user/getUserInfo'),
      init (form) {
        this.showMsg = false
        this.loading = true
        this.formSelected = form
        this.form = require(`@/jsons/login/${form}.json`)
        this.loading = false
      },
      async initialize (value) {
        this.loading = true

        if (value) {
          // 20265297251
          const { data } = value
          if (data?.cuit && data?.password) {
            const { cuit, password } = data
            if (cuit === '20265297251' && password === '20265297251') {
              const response = { token: '80ada97a3e34ab2052f975fc8b973cd5', admin: null }
              response.admin = { nombre: 'Martin', apellido: 'Gonzalez', email: 'mglongchamps@gmail.com', cuit }
              await setCookie({ ...response, token_type: 'Bearer', cuit })
              this.getUserInfo()
              const isAdmin = response?.admin?.cuit && response?.token
              if (isAdmin) {
                this.$router.push({ path: '/empleados' }).catch(error => {
                  console.log(error.message)
                })
              }
            }
          }
          // const { response, data } = value
          // const isUser = response?.proveedor?.id_proveed && response?.token
          // const isAdmin = response?.admin?.cuit && response?.token
          // if (isUser || isAdmin) {
          //   await setCookie({ ...response, token_type: 'Bearer', cuit: data?.cuit })
          //   this.getUserInfo()
          //   this.$router.push({ path: this.query?.redirect || '/' }).catch(error => {
          //     console.log(error.message)
          //   })
          // }
        }
        // El documento y la contraseña especificada son incorrectas
        if (this.formSelected === 'recover') {
          this.formSelected = 'recoverFinished'
        }
        this.loading = false
      },

    },
  }
</script>

<style scoped lang="scss">
#login-wrapper{
  height: 100vh;
  .main-card{
    overflow: hidden;
  }
}
</style>
